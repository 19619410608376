import React from "react";
import {Redirect, Route} from "react-router-dom";
import {
    HydraAdmin,
    hydraDataProvider as baseHydraDataProvider,
    fetchHydra as baseFetchHydra,
    ResourceGuesser,
    useIntrospection
} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import authProvider from "./authProvider";
import {KeersCreate, KeersEdit, KeersList} from "./Keers";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const getHeaders = () => {
    if (localStorage.getItem("auth")) {
        const {token} = JSON.parse(localStorage.getItem('auth'));
        return {Authorization: `Bearer ${token}`};
    }
    return {}
};
const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });
const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem("auth")) {
        introspect();
        return <></>;
    }
    return <Redirect to="/login"/>;
};
const apiDocumentationParser = async (entrypoint) => {
    try {
        const {api} = await parseHydraDocumentation(entrypoint, {headers: getHeaders});
        return {api};
    } catch (result) {
        if (result.status === 401) {
            // Prevent infinite loop if the token is expired
            localStorage.removeItem("auth");

            return {
                api: result.api,
                customRoutes: [
                    <Route path="/" component={RedirectToLogin}/>
                ],
            };
        }

        throw result;
    }
};
const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser);

const keerDataProvider = {
    ...dataProvider,
    createMany : (resource, data) => {
        fetchHydra(entrypoint + '/' + resource + '_bulk', {method:'post', body: JSON.stringify(data)})
            .catch(error => {
                if(error.hasOwnProperty('message')){
                    alert('errore nell\'upload, controllare i campi (' + error.message + ')');
                }
                window.location.reload();
            });
    }
}

export default function start() {
    return <HydraAdmin
        dataProvider={keerDataProvider}
        authProvider={authProvider}
        entrypoint={entrypoint}
    >
        <ResourceGuesser
            name="keers"
            create={KeersCreate}
            edit={KeersEdit}
            list={KeersList}
        />
        <ResourceGuesser name="images"/>
        <ResourceGuesser name="notes"/>
        <ResourceGuesser name="notifications"/>
        <ResourceGuesser name="users"/>
    </HydraAdmin>;
};