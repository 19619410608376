import {CreateGuesser, EditGuesser, InputGuesser} from "@api-platform/admin";
import {cloneElement} from 'react';
import React from "react";
import {Field} from 'react-final-form';
import {
    TextInput,
    SelectInput,
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    ShowButton,
    EditButton,
    sanitizeListRestProps,
    Pagination,
    List,
    Filter,
    Datagrid
} from "react-admin";
import {FieldGuesser} from "@api-platform/admin"
import {ImportButton, ImportConfig} from "react-admin-import-csv";

const LatLongInput = () => (
    <span>
        <Field name="position.latitude" component="input" type="number" placeholder="latitude"/>
        &nbsp;
        <Field name="position.longitude" component="input" type="number" placeholder="longitude"/>
    </span>
);

const KeersCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source="dec"/>
        <InputGuesser source="url"/>
        <InputGuesser source="slug"/>
        <LatLongInput source="position"/>
        <InputGuesser source="title"/>
        <SelectInput source="status" choices={[
            {id: 'inactive', name: 'Inactive'},
            {id: 'public', name: 'Public'},
            {id: 'private', name: 'Private'},
            {id: 'reserved', name: 'Reserved'},
        ]}/>
        <InputGuesser source="user"/>
    </CreateGuesser>
);

const KeersEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="dec"/>
        <InputGuesser source="url"/>
        <InputGuesser source="slug"/>
        <LatLongInput source="position"/>
        <InputGuesser source="title"/>
        <SelectInput source="status" choices={[
            {id: 'inactive', name: 'Inactive'},
            {id: 'public', name: 'Public'},
            {id: 'private', name: 'Private'},
            {id: 'reserved', name: 'Reserved'},
        ]}/>
        <InputGuesser source="user"/>
    </EditGuesser>
);

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
        total,
    } = useListContext();
    const config: ImportConfig = {
        parseConfig: {
            delimiter: ';'
        }
    }
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath}/>
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
            <ImportButton {...props} {...config} />
        </TopToolbar>
    );
};

const KeerPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const KeerFilter = props => (
    <Filter {...props}>
        <TextInput label="Slug" source="slug" />
        <TextInput label="Title" source="title" />
        <SelectInput label="Status" source="status" choices={[
            {id: 'inactive', name: 'Inactive'},
            {id: 'public', name: 'Public'},
            {id: 'private', name: 'Private'},
            {id: 'reserved', name: 'Reserved'},
        ]}/>
    </Filter>
);

const KeersList = props => (
    <List {...props} actions={<ListActions/>} pagination={<KeerPagination />} filters={<KeerFilter />}>
        <Datagrid>
            <FieldGuesser source="dec" />
            <FieldGuesser source="url" />
            <FieldGuesser source="slug" />
            <FieldGuesser source="title" />
            <FieldGuesser source="status" />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);
export {KeersEdit, KeersCreate, KeersList};